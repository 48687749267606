import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { DerechoHabienteModel } from '../model/derecho-habiente.model';

@Injectable({
  providedIn: 'root'
})
export class TrabajadorService {

  public headGCH: HttpHeaders;
  constructor(
    private http: HttpClient,
  ) {
    this.headGCH = new HttpHeaders();
    this.headGCH = this.headGCH.append('BasicAuthorization', 'Basic ' + btoa(environment.apiUsernameGCH + ':' + environment.apiPasswordGCH));
  }
  
  public getSupervisorList() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getSupervisorList`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }

  public getNacionalidadList() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getNacionalidadList`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }
  public _ListarSedeLaboral() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/_ListarSedeLaboral`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }
  public _ListarArea() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/_ListarArea`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }
  public _ListarSubdivision(Area) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/_ListarSubdivision?Area=${Area}`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }
  public _ListarSubplanilla(Subdivision) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/_ListarSubplanilla?Subdivision=${Subdivision}`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }
  public _ListarCargoTrabajador(Subplanilla) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/_ListarCargoTrabajador?Subplanilla=${Subplanilla}`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }
  public _ListarCondicionPostulante() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/_ListarCondicionPostulante`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }
  public _ListarStatusProceso() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/_ListarStatusProceso`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }

  public getContratados_list(model: any) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/getContratados_list`, model,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }

  public getContratadosSinc_list(model: any) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/getContratadosSinc_list`, model,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }
  

  public getFichasPersonal() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getFichasPersonal`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }

  
  public getModeloContrato_byPostulante(idpostulante) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getModeloContrato_byPostulante?Postulante=${idpostulante}`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }

  public verifyModeloContrato_byPostulanteID(idpostulante) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/verifyModeloContrato_byPostulanteID?Postulante=${idpostulante}`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }

  public getFormateriaTrabajo() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getFormateriaTrabajo`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }
  public getFormateriaTrabajo_byCuenta(concuenta:Boolean) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getFormateriaTrabajo_byCuenta?ConCuenta=${concuenta}`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }
  


  public getFotoByName(FileName) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getFotoByName?FileName=${FileName}`,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }

  // public getConvertB64Dox_to_B64PDF(b64string) {
  //   return this.http.post(`${environment.BaseUrlWS_GCH}/Converter/getConvertB64Dox_to_B64PDF`,{base64: b64string},
  //     { headers: this.headGCH })
  // .pipe( map( data => data ));
  // }
  


  public getModeloContrato_byPostulanteXML(xml) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/getModeloContrato_byPostulanteXML`,{XML: xml},
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }

  //MIGRACIÓN

  public getEmpresasList() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getEmpresasList`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public getDerechoHabientes_list(ids: string): Observable<Array<DerechoHabienteModel>> {
    return this.http.post<Array<DerechoHabienteModel>>(`${environment.BaseUrlWS_GCH}/Trabajador/getDerechoHabientes_list`,{
      ids
    },
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public updateAFPData(array_object) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/updateAFPData`,array_object,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public getModeloContratos(data) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/getModeloContratos`, data,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public getFormateriasTrabajo(data) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/getFormateriasTrabajo`, data,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  cargar(files: Array<any>): Observable<any> {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append(`file[${i}]`, files[i]);
    }

    const url = `${environment.BaseUrlWS_GCH}/Archivo/Cargar`;
    return this.http.post<any>(url, formData);
  }

  public importAccionMasivaPersonal(data) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/importAccionMasivaPersonal`, data,
    { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public getPostulantes_list(model: any) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/getPostulantes_list`,model,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }

  public sincronizarDatosPersonalHurpay(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/sincronizarDatosPersonalHurpay`, model,
    { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public actualizarSincronizacionData(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/actualizarSincronizacionData`, model,
    { headers: this.headGCH })
    .pipe( map( data => data ));
  }
  
  
  public sincronizarAltaPersonalHurpay(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/sincronizarAltaPersonalHurpay`, model,
    { headers: this.headGCH })
    .pipe( map( data => data ));
  }




  public importarNovLicenciasTrabajadores(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/importarNovLicenciasTrabajadores`, model,
    { headers: this.headGCH })
    .pipe( map( data => data ));
  }


  public importarNovVacacionesTrabajadores(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/importarNovVacacionesTrabajadores`, model,
    { headers: this.headGCH })
    .pipe( map( data => data ));
  }
  
  public sincronizarBajaPersonalHurpay(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/sincronizarBajasPersonalHurpay`, model,
    { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public sincronizarContratosPersonalHurpay(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/sincronizarContratosPersonalHurpay`, model,
    { headers: this.headGCH })
    .pipe( map( data => data ));
  }


  public sincronizarDNIPersonalHurpay(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/sincronizarDniPersonalHurpay`, model,
    { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public sincronizarSueldoPersonalHurpay(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/sincronizarSueldoPersonalHurpay`, model,
    { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public getTrabajadores_list(model: any) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/getTrabajadores_list`, model,
      { headers: this.headGCH })
  .pipe( map( data => data ));
  }

  public getCargaInicialCombos() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getCargaInicialCombos`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public getPostulantesEdicion(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/getPostulantesEdicion`, model,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public ActualizacionDatosPostulantes(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/ActualizacionDatosPostulantes`, model,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public ActualizacionDatosPostulantesIndividual(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/ActualizacionDatosPostulantesIndividual`, model,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public getListarHorarios() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getListarHorarios`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public getListarPersonalHorario(Horario) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getListarPersonalHorario?Horario=${Horario}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public registroMasivoHorarioPersonal(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/registroMasivoHorarioPersonal`, model,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public registroFechasDiferidos(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/registroFechasDiferidos`, model,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public getListarDescansosDiferidos(FechaInicial, FechaFinal) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/getListarDescansosDiferidos?FechaInicial=${FechaInicial}&FechaFin=${FechaFinal}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }
  
  public SaveUpdateFechaDiferido(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Trabajador/saveUpdateFechaDiferido`, model,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }
  
  public EliminarFechaDiferido(Fecha) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Trabajador/eliminarFechaDiferido?Fecha=${Fecha}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }
  

}
