import { Component, Inject, OnInit } from '@angular/core';
import { DateAdapter, MatDialog, MatDialogRef, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CentrosService } from '../../_core/services/centros.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { ReplaySubject, Subject } from 'rxjs';
import { ProyeccionPSService } from '../../_core/services/proyeccion.service';
import { takeUntil } from 'rxjs/operators';
import { MetaCosechaPSService } from '../../_core/services/metaCosecha.service';
import { JwtService } from '../../../../../shared/services/jwt.service';


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'm-save-update-meta-cosecha',
  templateUrl: './save-update-meta-cosecha.component.html',
  styleUrls: ['./save-update-meta-cosecha.component.scss'],
  providers: [ DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SaveUpdateMetaCosechaComponent implements OnInit {

  viewLoading: boolean = false;
	usuario: string;
  constructor(
    public dialogRef: MatDialogRef<SaveUpdateMetaCosechaComponent>,
    private _formBuilder_lote: FormBuilder, 
    public centros_s: CentrosService,
    public toastr: ToastrManager,
    private proyeccion_s: ProyeccionPSService,
    private datePipe: DatePipe,
    private jwtService: JwtService,
    private metaCosecha_s: MetaCosechaPSService,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  idMetaCosecha = 0;
  JWT_USER=this.jwtService.getUser();
  ngOnInit() {
    this.create_form();
    if(this.data.selected_data!=null){
      this.idMetaCosecha = this.data.selected_data.MetaCosecha;
      this.posible_cultivo = this.data.selected_data.Cultivo;
      this.posible_envase = this.data.selected_data.Envase;
      this.formMACRO.controls.Categorizacion.setValue(this.data.selected_data.Categorizacion);
      this.formMACRO.controls.Color.setValue(this.data.selected_data.Color);
      
      this.formMACRO.controls.RangoInicial.setValue(this.data.selected_data.RangoInicial);
      this.formMACRO.controls.RangoFinal.setValue(this.data.selected_data.RangoFinal);

      this.formMACRO.controls.FechaInicial.setValue(new Date(this.data.selected_data.FechaInicial));
      this.formMACRO.controls.FechaFinal.setValue(new Date(this.data.selected_data.FechaFinal));
    }
    this.getCultivo_cbo();
    this.getEnvase_cbo();
  }

  //#region  COMBOS
  public filterCultivo: FormControl = new FormControl();
  public filteredCultivo: ReplaySubject<any> = new ReplaySubject<any>();
  protected _onDestroy = new Subject<void>();
  array_Cultivo=[]; posible_cultivo = null;
  getCultivo_cbo() {
    this.proyeccion_s._getCultivo_cbo().subscribe((data: any) => {
      this.array_Cultivo = data;
      this.filteredCultivo.next(this.array_Cultivo.slice());
      this.filterCultivo.valueChanges.pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filtrar_cultivo();
        }
      );
      this.formMACRO.controls.Cultivo.setValue(this.posible_cultivo);
    }, ( errorServicio ) => {
      this.toastr.errorToastr('Se produjo un error cargar cultivos', 'Error!', {
        toastTimeout: 2000,
        showCloseButton: true,
        animate: 'fade',
        progressBar: true
      });
      console.log(errorServicio);
    });
  }
  filtrar_cultivo() {
    if (this.array_Cultivo.length === 0) {
      return;
    }
    let search = this.filterCultivo.value;
    if (!search) {
      this.filteredCultivo.next(this.array_Cultivo.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCultivo.next(
      this.array_Cultivo.filter(origen => origen.CultivoName.toLowerCase().indexOf(search) > -1)
    );
  }
  public filterEnvase: FormControl = new FormControl();
  public filteredEnvase: ReplaySubject<any> = new ReplaySubject<any>();
  array_Envase=[]; posible_envase = null;
  getEnvase_cbo() {
    this.metaCosecha_s._getEnvaseCbo().subscribe((data: any) => {
      this.array_Envase = data;
      this.filteredEnvase.next(this.array_Envase.slice());
      this.filterEnvase.valueChanges.pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filtrar_envase();
        }
      );
      this.formMACRO.controls.Envase.setValue(this.posible_envase);
    }, ( errorServicio ) => {
      this.toastr.errorToastr('Se produjo un error cargar envases', 'Error!', {
        toastTimeout: 2000,
        showCloseButton: true,
        animate: 'fade',
        progressBar: true
      });
      console.log(errorServicio);
    });
  }
  filtrar_envase() {
    if (this.array_Envase.length === 0) {
      return;
    }
    let search = this.filterEnvase.value;
    if (!search) {
      this.filteredEnvase.next(this.array_Envase.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEnvase.next(
      this.array_Envase.filter(origen => origen.Nombre.toLowerCase().indexOf(search) > -1)
    );
  }
  //#endregion

  save_update(){
    const _controls = this.formMACRO.controls; 
      if (this.formMACRO.invalid) {
        Object.keys(_controls).forEach(controlName =>
          _controls[controlName].markAsTouched()
        );
        this.toastr.warningToastr('Ingrese los campos obligatorios.', 'Advertencia!', {
          toastTimeout: 2000,
          showCloseButton: true,
          animate: 'fade',
          progressBar: true
        });
      }else{
        this.viewLoading = true;
        let model = {
          idMeta: this.idMetaCosecha,
          Cultivo: _controls.Cultivo.value,
          Envase: _controls.Envase.value,
          Categorizacion: _controls.Categorizacion.value,
          Color: _controls.Color.value,
          RInicial:_controls.RangoInicial.value,
          RFinal:_controls.RangoFinal.value,
          FInicio: this.datePipe.transform(_controls.FechaInicial.value,'yyyy-MM-dd'),
          FFinal: this.datePipe.transform(_controls.FechaFinal.value,'yyyy-MM-dd'),
          User: this.JWT_USER.LOGIN
        };
        this.metaCosecha_s._saveUpdateMetaCosecha(model).subscribe(
          (data: any) => {
            if(data[0].STATUS){
              this.toastr.successToastr(data[0].MSG, 'Éxito!', {
                toastTimeout: 2000,
                showCloseButton: true,
                animate: 'fade',
                progressBar: true
              });
            }else{
              this.toastr.warningToastr(data[0].MSG, 'Advertencia!', {
                toastTimeout: 2000,
                showCloseButton: true,
                animate: 'fade',
                progressBar: true
              });
            }
            this.viewLoading = false;
            this.dialogRef.close(true);
          }, ( errorServicio ) => {
            this.toastr.errorToastr(errorServicio.message, 'Error!', {
              toastTimeout: 2000,
              showCloseButton: true,
              animate: 'fade',
              progressBar: true
            });
            this.viewLoading = false;
            console.log(errorServicio);
          }
        );
          
      }
      return;
  }

  formMACRO: FormGroup;
  create_form(){
    this.formMACRO = this._formBuilder_lote.group({
      Cultivo: [null, Validators.required],
      Envase: [null, Validators.required],
      Categorizacion : [null, Validators.required],
      Color : [null, Validators.required],
      RangoInicial : [null, Validators.required],
      RangoFinal : [null, Validators.required],
      FechaInicial : [null, Validators.required],
      FechaFinal : [null, Validators.required]
    });
  }

  onNoClick(): void {
		this.dialogRef.close();
  }

  onYesClick() {
    this.viewLoading = true;
    this.dialogRef.close(true);
  }

}