import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MetaCosechaPSService {

    public headGI: HttpHeaders;
    constructor(
      private http: HttpClient,
    ) {
      this.headGI = new HttpHeaders();
      this.headGI = this.headGI.append('BasicAuthorization', 'Basic ' + btoa(environment.apiUsernameGI + ':' + environment.apiPasswordGI));
    }
    public _getEnvaseCbo() {
      return this.http.get(`${environment.BaseUrlWS_GI}/MetaCosecha/GI_getEnvaseCbo`,
        { headers: this.headGI })
      .pipe( map( data => data ));
    }
    public _getMetaCosechaList(cultivo) {
      return this.http.get(`${environment.BaseUrlWS_GI}/MetaCosecha/GI_getMetaCosechaList?prmCultivo=${cultivo}`,
        { headers: this.headGI })
      .pipe( map( data => data ));
    }
    public _saveUpdateMetaCosecha(model) {
      return this.http.post(`${environment.BaseUrlWS_GI}/MetaCosecha/GI_saveUpdateMetaCosecha`,model,
        { headers: this.headGI })
      .pipe( map( data => data ));
    }
    public _enableDisableMetaCosecha(id, future_status) {
      return this.http.post(`${environment.BaseUrlWS_GI}/MetaCosecha/GI_enableDisableMetaCosecha`,{ID: id,Future_Status: future_status},
        { headers: this.headGI })
      .pipe( map( data => data ));
    }
    
    public _migracionMetaCosecha(usr,array) {
      return this.http.post(`${environment.BaseUrlWS_GI}/MetaCosecha/GI_migracionMetaCosecha`,{User: usr, Data: array},
        { headers: this.headGI })
      .pipe( map( data => data ));
    }
  
}
