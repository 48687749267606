import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';

import * as Excel from 'exceljs/dist/exceljs.min.js';

@Component({
  selector: 'm-result-accion-masiva',
  templateUrl: './result-accion-masiva.component.html',
  styleUrls: ['./result-accion-masiva.component.scss']
})
export class ResultAccionMasivaComponent implements OnInit {

  viewLoading: boolean = false;

  array_data: Array<any> = [];
  Total: number = 0;
  CntOK: number = 0;
  CntError: number = 0;
  load_data: boolean = true;
  no_data: boolean = false;
  searchBan: boolean = false;

  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  sNameExcel: string; excelFileName: string;

  @ViewChild(MatSort) MatSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['DNI', 'Sociedad', 'FechaInicio', 'FechaFin', 'FechaCese','Accion', 'Estado', 'Mensaje'];
  cols_excel = ['DNI', 'Sociedad', 'Fecha Inicio Contrato', 'Fecha Fin Contrato', 'Fecha Cese', 'Accion', 'Estado', 'Mensaje'];

  constructor(
    public dialogRef: MatDialogRef<ResultAccionMasivaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.listData = new MatTableDataSource([]);
    this.listData.sort = this.MatSort;
    this.listData.paginator = this.paginator;
    this.load_data = true;
    this.no_data = false;
    this.searchBan = false;
    this.array_data = this.data.data.data;
    this.listarData();
  }

  listarData() {
    this.listData = new MatTableDataSource(this.array_data);
    this.listData.sort = this.MatSort;
    this.listData.paginator = this.paginator;
    this.load_data = true;
    this.no_data = true;
    this.searchBan = false;
    this.Total = this.array_data.length;
    this.CntOK = this.array_data.filter(d => d.Estado == 1).length;
    this.CntError = this.array_data.filter(d => d.Estado == 0).length;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  exportExcel() {
    if (this.array_data.length > 0) {
      this.sNameExcel = 'Resultado de Acción Masiva';
      this.excelFileName = `${this.sNameExcel}.xlsx`;
      const workbook = new Excel.Workbook();
      workbook.creator = 'Web';
      workbook.lastModifiedBy = 'Web';
      workbook.created = new Date();
      workbook.modified = new Date();
      workbook.addWorksheet(this.sNameExcel, { views: [{ ySplit: 0, xSplit: 20, activeCell: 'A1', showGridLines: true }] });
      const sheet = workbook.getWorksheet(1);
      sheet.getColumn(1).width = 30;
      sheet.getRow(1).values = this.cols_excel;

      sheet.columns = [
        { key: 'DNI', width: 10 },
        { key: 'Sociedad', width: 20 },
        { key: 'Fecha Inicio Contrato', width: 20 },
        { key: 'Fecha Fin Contrato', width: 20 },
        { key: 'Fecha Cese', width: 20 },
        { key: 'Accion', width: 20 },
        { key: 'Estado', width: 20 },
        { key: 'Mensaje', width: 30 },
      ];

      ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1','H1'].map(key => {
        sheet.getCell(key).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'eaeaea' }
        };
      });

      this.array_data.forEach(item => {
        sheet.addRow({
          DNI: item.DNI,
          'Sociedad': item.Sociedad,
          'Fecha Inicio Contrato': item.FechaInicio,
          'Fecha Fin Contrato': item.FechaFin,
          'Fecha Cese': item.FechaCese,
          'Accion': item.Accion,
          Estado: item.Estado?'OK':'ERROR',
          Mensaje: item.Mensaje
        });
      });

      workbook.xlsx.writeBuffer().then(array_data => {
        var file = new Blob([array_data], { type: this.blobType });
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file, this.excelFileName);
        } else { // Others
          var a = document.createElement("a"),
            url = URL.createObjectURL(file);
          a.href = url;
          a.download = this.excelFileName;
          document.body.appendChild(a);
          a.click();
          setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
        }
      });
    } else {
      /* this.toastr.infoToastr('No se encontraron datos', 'Información!', {
        toastTimeout: 4000,
        showCloseButton: true,
        animate: 'fade',
        progressBar: true
      }); */
    }
  }

}
