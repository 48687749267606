import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CentrosService {

  public headGCH: HttpHeaders;
    constructor(
      private http: HttpClient,
    ) {
      this.headGCH = new HttpHeaders();
      this.headGCH = this.headGCH.append('BasicAuthorization', 'Basic ' + btoa(environment.apiUsernameGCH + ':' + environment.apiPasswordGCH));
    }
  
  public getCentroCostoAsignacionList() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/CentroCostos/getCentroCostoAsignacionList`,
      { headers: this.headGCH })
        .pipe( map( data => data ));
  }
  public getCentroCostoList() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/CentroCostos/getCentroCostoList`,
      { headers: this.headGCH })
        .pipe( map( data => data ));
  }

  public enableDisableCentroCostos(table_id,id,future_status, user) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/CentroCostos/enableDisableCentroCostos`,{TableID: table_id,ID: id, FutureStatus: future_status, Username: user},
      { headers: this.headGCH })
        .pipe( map( data => data ));
  }

  public saveUpdateCentroCosto(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/CentroCostos/saveUpdateCentroCosto`,model,
      { headers: this.headGCH })
        .pipe( map( data => data ));
  }

  
  public saveUpdateCentroCostoAsignacion(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/CentroCostos/saveUpdateCentroCostoAsignacion`,model,
      { headers: this.headGCH })
        .pipe( map( data => data ));
  }
  
}
