import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { CargaMasivaService } from '../../_core/services/carga-masiva.service';
import { animate, trigger, style, transition, state } from '@angular/animations';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'm-tabla-carga-masiva',
  templateUrl: './tabla-carga-masiva.component.html',
  styleUrls: ['./tabla-carga-masiva.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({display: 'none', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      // transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TablaCargaMasivaComponent implements OnInit {

  viewLoading: boolean = false;
  usuario: string;

  expandedElement = null;
  dataExpanded = [];

  constructor(
    public dialogRef: MatDialogRef<TablaCargaMasivaComponent>,
    public ref_change : ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public cargaMasivaService: CargaMasivaService,
    public toastr: ToastrManager,
  ) { }
  
  isLoading = true;
  dataSource: MatTableDataSource<any>= new MatTableDataSource([]);
  @ViewChild(MatSort) MatSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['Tipo', 'Nombre', 'Estado', 'Total', 'Avance', 'Errores', 'FechaInicio', 'FechaFin', 'Descargado', 'FechaDescarga', 'Acciones'];

  searchKey = '';

  detalleCargaMasiva: Array<any> = [];

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.MatSort;
    this.dataSource.paginator = this.paginator;
    this.cargaMasivaService.listar().subscribe((response: any) => {
      let { cargaMasiva, detalleCargaMasiva } = response;
      this.detalleCargaMasiva = detalleCargaMasiva;

      cargaMasiva = cargaMasiva.map(e => {
        e.EstadoNombre = this.getEstadoCargaMasiva(e.Estado);
        e.EstadoCss = this.getCssEstadoCargaMasiva(e.Estado);
        return e;
      });

      this.dataSource = new MatTableDataSource(cargaMasiva);
      this.dataSource.sort = this.MatSort;
      this.dataSource.paginator = this.paginator;
      this.searchInside_Table();
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });

  }

  setExpandedElement(element) {
    this.dataExpanded = [];
    this.expandedElement = this.expandedElement === element ? null : element;
    if (this.expandedElement != null) {
      this.dataExpanded = this.detalleCargaMasiva.filter(e => e.CargaMasiva == this.expandedElement.CargaMasiva);
    }
  }

  searchInside_Table() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }

  getEstadoCargaMasiva(estado) {
    let name = '';
    switch(estado) {
      case 0: name = 'Pendiente'; break;
      case 1: name = 'Cargando'; break;
      case 2: name = 'Terminado con Errores'; break;
      case 3: name = 'Terminado Exitosamente'; break;
    }
    return name;
  }

  getCssEstadoCargaMasiva(estado) {
    let name = '';
    switch(estado) {
      case 0: name = 'metal'; break;
      case 1: name = 'info'; break;
      case 2: name = 'danger'; break;
      case 3: name = 'success'; break;
    }
    return name;
  }

  getEstadoDetalleCargaMasiva(estado) {
    let name = '';
    switch(estado) {
      case 0: name = 'Pendiente'; break;
      case 1: name = 'Cargando'; break;
      case 2: name = 'Error'; break;
      case 3: name = 'Éxito'; break;
    }
    return name;
  }

  descargar(estado, cargaMasiva) {
    if(estado == 2 || estado == 3) {
      let waitToastr = this.toastr.infoToastr('Por favor espera...', 'Descargando', {
        dismiss: 'controlled',
        showCloseButton: false,
        animate: 'fade',
        progressBar: true
      });
        this.cargaMasivaService.descargar(cargaMasiva)
        .subscribe(response => {
          waitToastr.dismiss();
          if (response.ok) {
            this.cargaMasivaService.descargarBase64(response.base64, response.name);
            this.toastr.successToastr('Archivo descargado...', 'Éxito!', {
              toastTimeout: 3000,
              showCloseButton: true,
              animate: 'fade',
              progressBar: true
            });
            this.loadData();
          } else {
            this.toastr.errorToastr(response.message, 'Error!', {
              toastTimeout: 3000,
              showCloseButton: true,
              animate: 'fade',
              progressBar: true
            });
          }
        },(error) => {
          waitToastr.dismiss();
          this.toastr.errorToastr(error.message, 'Error!', {
            toastTimeout: 3000,
            showCloseButton: true,
            animate: 'fade',
            progressBar: true
          });
        });
    }
  }

  descargarDetalle(estado, detalleCargaMasiva) {
    if(estado == 3) {
      let waitToastr = this.toastr.infoToastr('Por favor espera...', 'Descargando', {
        dismiss: 'controlled',
        showCloseButton: false,
        animate: 'fade',
        progressBar: true
      });
      this.cargaMasivaService.descargarDetalle(detalleCargaMasiva)
        .subscribe(response => {
          waitToastr.dismiss();
          if (response.ok) {
            this.cargaMasivaService.descargarBase64(response.base64, response.name);
            this.toastr.successToastr('Archivo descargado...', 'Éxito!', {
              toastTimeout: 3000,
              showCloseButton: true,
              animate: 'fade',
              progressBar: true
            });
            this.loadData();
          } else {
            this.toastr.errorToastr(response.message, 'Error!', {
              toastTimeout: 3000,
              showCloseButton: true,
              animate: 'fade',
              progressBar: true
            });
          }
        },(error) => {
          waitToastr.dismiss();
          this.toastr.errorToastr(error.message, 'Error!', {
            toastTimeout: 3000,
            showCloseButton: true,
            animate: 'fade',
            progressBar: true
          });
        });
    }
  }

  onNoClick(): void {
		this.dialogRef.close();
  }

}
