import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SessionKeys, TokenStorage } from '../../content/pages/auth/_core/services/token-storage.service';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../content/pages/auth/_core/services/authentication.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private _tokenStorage: TokenStorage,
                private _authenticationService: AuthenticationService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = this._tokenStorage.get(SessionKeys.TOKEN);
        if (token) {
            request = request.clone({ headers: request.headers.set(SessionKeys.TOKEN, 'Bearer ' + token) })
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.headers.get(SessionKeys.TOKEN)) {
                        this._tokenStorage.set(SessionKeys.TOKEN, event.headers.get(SessionKeys.TOKEN));
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401 ||
                        // error.status === 400 ||
                        error.status === 0) {
                        //this._authenticationService.logout(true);
                    }
                }
                return throwError(error);
            })
        );
    }
}
