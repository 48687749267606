import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage, SessionKeys } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from '../interfaces/access-data';
import { Credential } from '../interfaces/credential';
import { environment } from '../../../../../../environments/environment';
import { JwtService } from '../../../../../shared/services/jwt.service';

@Injectable()
export class AuthenticationService implements AuthService {
	API_URL = 'api';
	API_ENDPOINT_LOGIN = '/login';
	API_ENDPOINT_REFRESH = '/refresh';
	API_ENDPOINT_REGISTER = '/register';

	public onCredentialUpdated$: Subject<AccessData>;

	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private jwtService: JwtService,
		private util: UtilsService
	) {
		this.onCredentialUpdated$ = new Subject();
	}

	private get headersBasicAuthorization() {
        let headers = new HttpHeaders();
        headers = headers.set('BasicAuthorization', 'Basic ' + btoa(environment.apiUsernameIT + ':' + environment.apiPasswordIT));
        return headers;
    }

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<any> {
		let me = this;
		return this.http.get<AccessData>(`${environment.BaseUrlWS_IT}/Seguridad/refreshToken`,{
			headers: this.headersBasicAuthorization
		}).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				} else {
					return undefined;
				}
			}),
			tap(this.saveRefreshAccessData.bind(this)),
			catchError(this.handleError('login', []))
		);
	}

	private saveRefreshAccessData(accessData: AccessData) {
		if (typeof accessData !== 'undefined') {
			this.tokenStorage.set(SessionKeys.TOKEN, accessData.Token);
			this.jwtService.load(accessData.Token);
		}
	}
	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		return url.endsWith(this.API_ENDPOINT_REFRESH);
	}

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */


	public login(Login: string, Password: string): Observable<any> {
		let me = this;
		return this.http.post(`${environment.BaseUrlWS_IT}/Seguridad/ZITG_Login`,
		{
			login: Login,
			password: Password
		}, {
			headers: this.headersBasicAuthorization
		}).pipe(
			map((result: any) => {
				console.log(result);
				if (result instanceof Array) {
					return result.pop();
				} else {
					return undefined;
				}
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(this.handleError('login', []))
		);
	}

	public getAccesosDNP(){
		return this.http.get(`${environment.BaseUrlWS_IT}/Seguridad/ZITG_ObtenerOpcionesUsuario?prmstrApplication=${environment.codigoSistema}`, {
			headers: this.headersBasicAuthorization
		}).pipe(map(data => data));
	}

	public getDatoSession(key: any){
		return this.tokenStorage.get(key);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return from(result);
		};
	}

	/**
	 * Logout
	 */
	public logout(refresh?: boolean): void {
		this.tokenStorage.clear();
		if (refresh) {
			location.reload(true);
		}
	}

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
	private saveAccessData(accessData: AccessData) {
		if (typeof accessData !== 'undefined') {
			this.tokenStorage.set(SessionKeys.USUARIOID, accessData.IdUsuario);
			this.tokenStorage.set(SessionKeys.USUARIO, accessData.Login);
			this.tokenStorage.set(SessionKeys.NOMBRE, accessData.Nombre);
			this.tokenStorage.set(SessionKeys.EMPRESA, accessData.empresa);
			this.tokenStorage.set(SessionKeys.SOCIEDAD, accessData.empresa);
			this.tokenStorage.set(SessionKeys.AREA, accessData.DesUnidadOrganizativa);
			this.tokenStorage.set(SessionKeys.CECO, accessData.CentroCosto);
			this.tokenStorage.set(SessionKeys.TRABAJADOR, accessData.Trabajador);
			this.tokenStorage.set(SessionKeys.EMAIL, accessData.Email);
			this.tokenStorage.set(SessionKeys.POSICION, accessData.DesPosicion);
			this.tokenStorage.set(SessionKeys.TIPOUSUARIO, accessData.TipoUsuario);
			this.tokenStorage.setAccessRol("2");
			this.tokenStorage.setUserRoles("[\"ADMIN\"]");
			this.tokenStorage.set(SessionKeys.TOKEN, accessData.Token);
			this.jwtService.load(accessData.Token);
			this.onCredentialUpdated$.next(accessData);
		}
	}

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public register(credential: Credential): Observable<any> {
		// dummy token creation
		credential = Object.assign({}, credential, {
			accessToken: 'access-token-' + Math.random(),
			refreshToken: 'access-token-' + Math.random(),
			roles: ['USER'],
		});
		return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential)
			.pipe(catchError(this.handleError('register', []))
		);
	}

	forgotPassword(dominio:string, email: string, login: string) {
        return this.http.post(`${environment.BaseUrlWS_IT}/Seguridad/ForgotPassword`, 
            {
                dominio: dominio,
                email: email,
                login: login
            }, {
				headers: this.headersBasicAuthorization
            }
        )
	}
	
	/**
	 * Submit forgot password request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public requestPassword(credential: Credential): Observable<any> {
		return this.http.get(this.API_URL + this.API_ENDPOINT_LOGIN + '?' + this.util.urlParam(credential))
			.pipe(catchError(this.handleError('forgot-password', []))
		);
	}

}
