import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpInterceptor, HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';

import 'hammerjs';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from './content/pages/auth/authentication.module';


import { NgxPermissionsModule } from 'ngx-permissions';

import { LayoutModule } from './content/layout/layout.module';
import { PartialsModule } from './content/partials/partials.module';
import { ConfigModule } from './config/config.module';
import { AclService } from './config/_core/services/acl.service';


import { LayoutConfigService } from './config/_core/services/layout-config.service';
import { MenuConfigService } from './content/pages/auth/_core/services/menu-config.service';
import { PageConfigService } from './config/_core/services/page-config.service';
import { UserService } from './content/pages/auth/_core/services/user.service';


import { UtilsService } from './content/pages/auth/_core/services/utils.service';
import { ClassInitService } from './config/_core/services/class-init.service';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig, MatProgressSpinnerModule, MatDialog, MatDialogModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';

import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LayoutConfigStorageService } from './config/_core/services/layout-config-storage.service';



import { SubheaderService } from './config/_core/services/subheader.service';
import { HeaderService } from './config/_core/services/header.service';
import { MenuHorizontalService } from './config/_core/services/menu-horizontal.service';
import { MenuAsideService } from './config/_core/services/menu-aside.service';
import { LayoutRefService } from './config/_core/services/layout-ref.service';
import { SplashScreenService } from './config/_core/services/splash-screen.service';

import { ToastrModule } from 'ng6-toastr-notifications';

import { FormsModule } from '@angular/forms';

import { MatIconModule, MatIconRegistry } from '@angular/material';
import { HttpConfigInterceptor } from './security/interceptors/HttpConfigInterceptor';

import { QRCodeModule } from 'angularx-qrcode';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	// suppressScrollX: true
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		LayoutModule,
		PartialsModule,
		ConfigModule,
		OverlayModule,
		AuthenticationModule,
		NgxPermissionsModule.forRoot(),
		NgbModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		FormsModule,
		ToastrModule.forRoot(),
		MatIconModule,
		MatDialogModule,
		QRCodeModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
		AclService,
		LayoutConfigService,
		LayoutConfigStorageService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		UserService,
		UtilsService,
		ClassInitService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},

		// template services
		SubheaderService,
		HeaderService,
		MenuHorizontalService,
		MenuAsideService,
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},
		MatIconRegistry
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(public matIconRegistry: MatIconRegistry) {
        matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    }
}
