import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CampanyaService } from '../../../_core/services/campanya.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { JwtService } from '../../../../../../shared/services/jwt.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'm-save-update-campanya',
  templateUrl: './save-update-campanya.component.html',
  styleUrls: ['./save-update-campanya.component.scss'],
  providers: [ DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SaveUpdateCampanyaComponent implements OnInit {
  viewLoading: boolean = false;
	usuario: string;
  constructor(
    public toastr: ToastrManager,
    public dialogRef: MatDialogRef<SaveUpdateCampanyaComponent>,
    private change_ref: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private campanya_s: CampanyaService,
    private jwtService: JwtService,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  id_cliente:number;
  JWT_USER=this.jwtService.getUser();
  ngOnInit() {
    //console.log(this.JWT_USER);
    this.create_form();   
    if(this.data.selected_data){
      let selected = this.data.selected_data;
      this.idCampana = selected.Campanya;
      //this.posible_fundo=selected.Fundo;
      this.posible_modulo = selected.Modulo;
      this.posible_turno = selected.Turno;
      //this.posible_lote = selected.Lote;
      this.getModulos_cbo(0);
      this.getTurnos_cbo(selected.Modulo);
      //this.getLotes_cbo(selected.Turno);

      const controls = this.formGenerales.controls;
      controls.Codigo.setValue(selected.CodigoCampanya);

      controls.InicioCampana.setValue(selected.InicioCampanya);
      controls.FinCampana.setValue(selected.FinCampanya);
      controls.InicioCosecha.setValue(selected.InicioCosecha);
      controls.FinCosecha.setValue(selected.FinCosecha);

    }else{
      this.idCampana=0;
      //this.getFundos_cbo();
      this.getModulos_cbo(0);
    }
  }

  //#region  COMBOS
  protected _onDestroy = new Subject<void>();
  
 /* public filterFundo: FormControl = new FormControl();
  public filteredFundo: ReplaySubject<any> = new ReplaySubject<any>();
  protected _onDestroy = new Subject<void>();
  array_Fundo=[];posible_fundo=null;
  getFundos_cbo() {
    this.campanya_s.getFundoLst('',1).subscribe((data: any) => {
      //data.unshift({Fundo:0, NombreFundo:'Todos'})
      this.array_Fundo = data;
      this.filteredFundo.next(this.array_Fundo.slice());
      this.filterFundo.valueChanges.pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filtrar_fundo();
        }
      );
      this.formGenerales.controls.Fundo.setValue(this.posible_fundo);
    }, ( errorServicio ) => {
      this.toastr.errorToastr('Se produjo un error cargar proveedores', 'Error!', {
        toastTimeout: 2000,
        showCloseButton: true,
        animate: 'fade',
        progressBar: true
      });
      console.log(errorServicio);
    });
  }
  filtrar_fundo() {
    if (this.array_Fundo.length === 0) {
      return;
    }
    let search = this.filterFundo.value;
    if (!search) {
      this.filteredFundo.next(this.array_Fundo.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredFundo.next(
      this.array_Fundo.filter(origen => origen.NombreFundo.toLowerCase().indexOf(search) > -1)
    );
  }*/

  public filterModulo: FormControl = new FormControl();
  public filteredModulo: ReplaySubject<any> = new ReplaySubject<any>();
  array_Modulo=[]; posible_modulo=null;
  getModulos_cbo(idFundo) {
    this.campanya_s.getModuloByFundo(0,1).subscribe((data: any) => {
      //data.unshift({Modulo:0, NombreModulo:'Todos'})
      this.array_Modulo = data;
      this.filteredModulo.next(this.array_Modulo.slice());
      this.filterModulo.valueChanges.pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filtrar_modulo();
        }
      );
      this.formGenerales.controls.Modulo.setValue(this.posible_modulo);
    }, ( errorServicio ) => {
      this.toastr.errorToastr('Se produjo un error cargar proveedores', 'Error!', {
        toastTimeout: 2000,
        showCloseButton: true,
        animate: 'fade',
        progressBar: true
      });
      console.log(errorServicio);
    });
  }
  filtrar_modulo() {
    if (this.array_Modulo.length === 0) {
      return;
    }
    let search = this.filterModulo.value;
    if (!search) {
      this.filteredModulo.next(this.array_Modulo.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredModulo.next(
      this.array_Modulo.filter(origen => origen.NombreModulo.toLowerCase().indexOf(search) > -1)
    );
  }

  public filterTurno: FormControl = new FormControl();
  public filteredTurno: ReplaySubject<any> = new ReplaySubject<any>();
  array_Turno=[]; posible_turno=null;
  getTurnos_cbo(idModulo) {
    this.campanya_s.getTurnoByModulo(idModulo,1).subscribe((data: any) => {
      this.array_Turno = data;
      this.filteredTurno.next(this.array_Turno.slice());
      this.filterTurno.valueChanges.pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filtrar_turno();
        }
      );
      this.formGenerales.controls.Turno.setValue(this.posible_turno);
    }, ( errorServicio ) => {
      this.toastr.errorToastr('Se produjo un error cargar proveedores', 'Error!', {
        toastTimeout: 2000,
        showCloseButton: true,
        animate: 'fade',
        progressBar: true
      });
      console.log(errorServicio);
    });
  }
  filtrar_turno() {
    if (this.array_Turno.length === 0) {
      return;
    }
    let search = this.filterTurno.value;
    if (!search) {
      this.filteredTurno.next(this.array_Turno.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTurno.next(
      this.array_Turno.filter(origen => origen.NombreTurno.toLowerCase().indexOf(search) > -1)
    );
  }

  public filterLote: FormControl = new FormControl();
  public filteredLote: ReplaySubject<any> = new ReplaySubject<any>();
  array_Lote=[]; posible_lote=null;
  getLotes_cbo(idTurno) {
    this.campanya_s.getLotesByTurno(idTurno,1).subscribe((data: any) => {
      this.array_Lote = data;
      this.filteredLote.next(this.array_Lote.slice());
      this.filterLote.valueChanges.pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filtrar_lote();
        }
      );
      this.formGenerales.controls.Lote.setValue(this.posible_lote);
    }, ( errorServicio ) => {
      this.toastr.errorToastr('Se produjo un error cargar proveedores', 'Error!', {
        toastTimeout: 2000,
        showCloseButton: true,
        animate: 'fade',
        progressBar: true
      });
      console.log(errorServicio);
    });
  }
  filtrar_lote() {
    if (this.array_Turno.length === 0) {
      return;
    }
    let search = this.filterLote.value;
    if (!search) {
      this.filteredLote.next(this.array_Lote.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredLote.next(
      this.array_Lote.filter(origen => origen.NombreLote.toLowerCase().indexOf(search) > -1)
    );
  }
  //#endregion

  idCampana=0;

  onNoClick(): void {
		this.dialogRef.close();
  }
  onYesClick() {
    const  controls = this.formGenerales.controls;
    if(this.formGenerales.invalid){
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.toastr.warningToastr('Asegurece de tener todos los datos llenos y al menos un item en el detalle', 'Advertencia!', {
        toastTimeout: 2000,
        showCloseButton: true,
        animate: 'fade',
        progressBar: true
      });
    }else{
      this.viewLoading = true;
      let model=this.prepare_model();
      this.campanya_s.saveUpdateCampanya(model).subscribe((data: any) => {
        if(data[0].STATUS){
          this.toastr.successToastr(data[0].MSG, 'Éxito!', {
            toastTimeout: 2000,
            showCloseButton: true,
            animate: 'fade',
            progressBar: true
          });
          this.dialogRef.close(true);
          this.viewLoading = false;
        }else{
          this.toastr.warningToastr(data[0].MSG, 'Advetencia!', {
            toastTimeout: 2000,
            showCloseButton: true,
            animate: 'fade',
            progressBar: true
          });
          this.viewLoading = false;
        }
      }, ( errorServicio:Error ) => {
        this.toastr.errorToastr(errorServicio.message, 'Error!', {
          toastTimeout: 2000,
          showCloseButton: true,
          animate: 'fade',
          progressBar: true
        });
        console.log(errorServicio);
      });
      
    }
  }
  prepare_model(){
    const controls = this.formGenerales.controls;
    return {
      ID: this.idCampana,
      Turno: controls.Turno.value,
      Codigo: controls.Codigo.value,
      InicioCampanya: this.datePipe.transform(controls.InicioCampana.value,'yyyy-MM-dd'),
      FinCampanya: this.datePipe.transform(controls.FinCampana.value,'yyyy-MM-dd'),
      InicioCosecha: this.datePipe.transform(controls.InicioCosecha.value,'yyyy-MM-dd'),
      FinCosecha: this.datePipe.transform(controls.FinCosecha.value,'yyyy-MM-dd'),
      Login: this.JWT_USER.LOGIN,
    };
  }


  formGenerales:FormGroup;
  create_form(){
    this.formGenerales = this._formBuilder.group({
      //Fundo:[null,Validators.required],
      Modulo:[null,Validators.required],
      Turno:[null,Validators.required],
      Lote: [null],
      Codigo:[null,Validators.required],
      InicioCampana:[new Date(),Validators.required],
      FinCampana:[new Date(),Validators.required],
      InicioCosecha:[new Date(),Validators.required],
      FinCosecha:[new Date(),Validators.required],
    });
  }
}

