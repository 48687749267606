import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TrabajadorService } from '../../_core/services/trabajador.service';
import { CloudService } from '../../_core/services/cloud.service';
import { DatePipe } from '@angular/common';
import { PostulanteModel } from '../../_core/model/postulante.model';
import { DerechoHabienteModel } from '../../_core/model/derecho-habiente.model';

@Component({
  selector: 'm-trabajador-info-dialog',
  templateUrl: './trabajador-info-dialog.component.html',
  styleUrls: ['./trabajador-info-dialog.component.scss'],
  providers: [ DatePipe]
})
export class TrabajadorInfoDialogComponent implements OnInit {
  viewLoading: boolean = false;
  usuario: string;

  postulante: PostulanteModel = new PostulanteModel();

  constructor(
    public toastr: ToastrManager,
    public dialogRef: MatDialogRef<TrabajadorInfoDialogComponent>,
    public trabajador_s: TrabajadorService,
    public cloud_s: CloudService,
    private datePipe: DatePipe,
    public ref_change : ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  is_loading = true;

  ngOnInit() {
    console.log(this.data)
    this.postulante = this.data.postulante;
    this.is_loading = false;
  }

  onNoClick(): void {
		this.dialogRef.close();
  }

  statusText(status) {
    if (status) {
      return 'SÍ';
    } else {
      return 'NO';
    }
  }

  formatNumber(n: number){
    var val = n.toFixed(2);
    var parts = val.toString().split(".");
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");

    return num;
  }
}
