import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'm-observacion-dialog',
  templateUrl: './observacion-dialog.component.html',
  styleUrls: ['./observacion-dialog.component.scss']
})
export class ObservacionDialogComponent implements OnInit {
  viewLoading: boolean = false;
  usuario: string;

  constructor(
    public dialogRef: MatDialogRef<ObservacionDialogComponent>,
    public ref_change : ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  is_loading = true;
  ngOnInit() {
  }

  onNoClick(): void {
		this.dialogRef.close();
  }


}
