import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProyeccionPSService {

    public headGCH: HttpHeaders;
    constructor(
      private http: HttpClient,
    ) {
      this.headGCH = new HttpHeaders();
      this.headGCH = this.headGCH.append('BasicAuthorization', 'Basic ' + btoa(environment.apiUsernameGI + ':' + environment.apiPasswordGI));
    }


  public _getCultivo_cbo() {
    return this.http.get(`${environment.BaseUrlWS_GI}/Proyeccion/GI_getCultivo_cbo`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public _getProyeccionesLst(anio, cultivo, variedad, nombre) {
    return this.http.get(`${environment.BaseUrlWS_GI}/Proyeccion/GI_getProyeccionesLst?prmAnio=${anio}&prmCultivo=${cultivo}&prmVariedad=${variedad}&prmNombre=${nombre}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public _getProyeccionData(id) {
    return this.http.get(`${environment.BaseUrlWS_GI}/Proyeccion/GI_getProyeccionData?prmintProyeccion=${id}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public _saveUpdateProyeccion(model) {
    return this.http.post(`${environment.BaseUrlWS_GI}/Proyeccion/GI_saveUpdateProyeccion`,model,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }
  public _migracionProyecciones(usr,array) {
    return this.http.post(`${environment.BaseUrlWS_GI}/Proyeccion/GI_migracionProyecciones`,{User: usr, Detas: array},
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }
  public enableDisableProyeccion(id, future_status) {
    return this.http.post(`${environment.BaseUrlWS_GI}/Proyeccion/GI_enableDisableProyeccion`,{ID: id,Future_Status: future_status},
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

}
