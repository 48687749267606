import { DerechoHabienteModel } from './derecho-habiente.model';

export class PostulanteModel {
    Postulante: number;
    NroPersonal: any;
    DNI: string;
    Nombres: string;
    EmpresaName: string;
    Apellidos: string;
    FullName: string;
    Sede: string;
    FechaPostulacion: string;
    StatusPostulacion_Val: string;
    StatusPostulacion: string;
    CondicionPostulante_Val: string;
    CondicionPostulante: string;
    Cargo_Val: string;
    GeneroAbr: string;
    Telefono1: string;
    Telefono2: string;
    CodBanco: string;
    CodMonedaBanco: string;
    CodBancoCTS: string;
    NroCtaCts: string;
    CodMoneda: string;
    Numero: string;
    Cargo: string;
    DireccionInterior: string;
    DirDepartamento: string;
    DirManzana: string;
    DirEtapa: string;
    DirBlock: string;
    DirLote: string;
    DirKilometro: string;
    CZona: string;
    DescripcionZona: string;
    DireccionReferencia: string;
    AutogeneradoIPSS: string;
    GrupoTrabajo: string;
    CCostoOrigen: string;
    FCese: string;
    SubplanillaN: string;
    EPS: string;
    HorarioTrabajo: string;
    NroCtaCCI: string;
    NroCtaCCICTS: string;
    InicioContrato: string;
    FinContrato: string;
    FechaNacimiento: string;
    Sexo: string;
    Observacion: any;
    URL_FOTO: string;
    Direccion: string;
    TipoContrato: string;
    CodEmpresa: string;
    FechaCaducidad: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    Remuneracion: string;
    Sede_Val: string;
    Edad: number;
    MesesContrato: number;
    Semanas: number;
    FinContratoPrimerDia: string;
    FinMesInicioContrato: string;
    Banco: string;
    NroCuentaCCI: string;
    CategoriaPensionista: string;
    GradoInstruccion: string;
    Sueldo: number;
    EstadoCivil: string;
    EstadoCivilVal: string;
    DireccionActual: string;
    DistritoActual: any;
    ProvinciaActual: any;
    DepartamentoActual: any;
    CategoriaPensionista1: string;
    TipoDocumento: string;
    TipoDocumentoName: string;
    Pais: string;
    NivelEducativo: string;
    NivelEdicativoVAL: string;
    EmpresaVal: number;
    Telefono: string;
    ubigeo: any;
    PeriodoPrueba: string;
    PeriodoPrueba6Meses: string;
    PeriodoPrueba12Meses: string;
    DepartamentoDNI: string;
    ProvinciaDNI: string;
    DistritoDNI: string;
    DireccionDNI: string;
    EstadoCivilNS: string;
    NivelEducativoNS: string;
    PaisNS: string;
    TipoDocumentoNS: string;
    Correo: string;
    CategoriaPensionistaNS: string;
    CargoCodigoNS: any;
    NacionalidadNS: string;
    CUSPP: any;
    BancoCodigoNS: string;
    CuentaBancoNS: string;
    ImagenHuella: any;
    direccion_referencia: string;
    CatOcupacional: any;
    CatOcupacionalNombre: any;
    CatOcupacionalValor1: any;
    PeriodPago: any;
    PeriodPagoNombre: any;
    PeriodPagoValor1: any;
    TipoPago: any;
    TipoPagoNombre: any;
    TipoPagoValor1: any;
    SituacEspecial: any;
    SituacEspecialNombre: any;
    SituacEspecialValor1: any;
    TrabHorNocturno: any;
    TrabHorNocturnoNombre: any;
    TrabHorNocturnoValor1: any;
    JornTrabMax: any;
    JornTrabMaxNombre: any;
    JornTrabMaxValor1: any;
    RegAtipico: any;
    RegAtipicoNombre: any;
    RegAtipicoValor1: any;
    CodPlanilla: any;
    CodPlanillaNombre: any;
    CodPlanillaValor1: any;
    CodTipoTrab: any;
    CodTipoTrabNombre: any;
    CodTipoTrabValor1: any;
    CodSucursal: any;
    CodSucursalNombre: any;
    CodSucursalValor1: any;
    GrupoProvision: any;
    GrupoProvisionNombre: any;
    GrupoProvisionValor1: any;
    RegLaboral: any;
    RegLaboralNombre: any;
    RegLaboralValor1: any;
    Empresa: number;
    EmpresaRuc: string;
    EmpresaRazonSocial: string;
    Selection: boolean;
    SubArea: string;
    CategoriaPensionistaName: string;

    Area: string;
    AreaNombre: string;
    AreaValor1: string;
    Subarea: string;
    SubareaNombre: string;
    SubareaValor1: string;

    TipoCuentaBancaria: string;
    TipoCuentaBancariaNombre: string;
    // Banco: string; 
    BancoNombre: string;
    BancoValor1: string;
    NroCuenta: string;
    // NroCuentaCCI: string;
    Subdivision: string;
    SubdivisionNombre: string;
    Basico: string;


    derechoHabientes: Array<DerechoHabienteModel> = [];

    //NUEVOS NOMBRE

    Genero: string;
    CodVia: string;
    TipoVia: string;
    DescripcionVia: string;
    TipPersSubpl: string;
    TipoComision: number;
    CodAFP: string;

    EvalPsicologica: number;
    ServicioSalud: number;

    //CONTACTO EMERGENCIA

    NombreContactoEmergencia: string;
    NroContactoEmergencia: string;
    ParentescoContactoEmergencia: string;
    NombreParentesco: string;

    //FICHA MÉDICA

    OtrasEnfermedades: string = '';
    Cirugias: string = '';
    AntecGinecologicos: string = '';
    UltimoPapaNicolaou: string = '';
    esAlcohol: string = '';
    esTabaco: string = ''; 
    Peso: string = '';
    Talla: string = '';
    FrecuenciaCardiaca: string = '';
    FrecuenciaRespiratoria: string = '';
    Saturacion: string = '';
    Temperatura: string = '';
    PresionArterial: string = '';
    IndiceMasaCorporal: string = '';
    Anamnesis: string = '';
    ExamenFisico: string = '';
    Diagnostico: string = '';
    Aptitud: string = '';
    esAlergias: string = ''; 
    esDiabetes: string = ''; 
    esHepatitis: string = ''; 
    esCervicalgia: string = ''; 
    esETS: string = ''; 
    esAsmaBronquial: string = ''; 
    esHTA: string = ''; 
    esTuberculosis: string = ''; 
    esDorsalgia: string = ''; 
    esETAS: string = ''; 
    esBronquitisCronica: string = ''; 
    esNeoplasia: string = ''; 
    esConvulsiones: string = ''; 
    esLumbalgia: string = ''; 
    esPsicologico: string = '';

    //CAMBIOS 12-10-2020

    MACNombre: string = '';
    TipoAlcohol: string = '';
    CantidadAlcohol: string = '';
    FrecuenciaAlcohol: string = '';
    TipoTabaco: string = '';
    CantidadTabaco: string = '';
    FrecuenciaTabaco: string = '';

    CentroTrabajo: string = '';
    PuestoTrabajo: string = '';
    PrincipalesRiesgos: string = '';
    TiempoTrabajo: string = '';
    UsabaEPPS: string = '';
    Eventualidad: string = '';
    Descripcion: string = '';
    MesAnio: string = '';
    DiasPerdidos: string = '';

    GrupoSanguineo: string = '';

    RepresentanteLegalNombre: string = '';
    RepresentanteLegalDNI: string = '';
    RepresentanteLegalDireccion: string = '';
    SedeCiudad: string = '';
    PagoPeriodo: string = '';

    esSPP: string = '';
    esSNP: string = '';


}
