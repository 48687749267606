export const environment = {
	jwt: {
		audience: 'DefaultAudience',
		issuer: 'DefaultIssuer'
	  },	
	production: true,
	codigoSistema: '0014',
	isMockEnabled: true, // You have to switch this, when your real back-end is done 
	timeToRefreshToken: 8000,

	/* RUTAS DE WEB SERVICES */
	//BaseUrlWS_IT: 'http://192.168.1.203:8005/WS_IT/api',
	//BaseUrlWS_IT: 'http://192.168.1.203:8005/WS_VT/api',
	//BaseUrlWS_IT: 'http://192.168.1.203:8005/WS_FT/api',

	// BaseUrlWS_IT: 'http://35.190.132.15:9010/WS_IT/api',
	// BaseUrlWS_GCH: 'http://35.190.132.15:9010/WS_GCH/api',
	// BaseUrlWS_GI: 'http://35.190.132.15:9010/WS_GI/api',
	// BaseUrlWS_PS: 'http://35.190.132.15:9010/WS_PS/api',

	// BaseUrlWS_IT: 'https://api.intranetcoexa.com:9050/WS_IT/api',
	// BaseUrlWS_GCH: 'https://api.intranetcoexa.com:9050/WS_GCH/api',
	// BaseUrlWS_GI: 'https://api.intranetcoexa.com:9050/WS_GI/api',
	// BaseUrlWS_PS: 'https://api.intranetcoexa.com:9050/WS_PS/api',

	BaseUrlWS_IT: 'https://api.intranetcoexa.com:9050/WS_IT/api',
	BaseUrlWS_GCH: 'https://api.intranetcoexa.com:9050/WS_GCH/api',
	BaseUrlWS_GI: 'https://api.intranetcoexa.com:9050/WS_GI/api',
	BaseUrlWS_PS: 'https://api.intranetcoexa.com:9050/WS_PS/api',
	// ws.danper.com:8983/


	/* USUARIOS AUTORIZADOS */
	apiUsernameIT: 'SysEvalDesWS_IT',
	apiUsernameGCH: 'SysEvalDesWS_GCH',
	apiUsernameGI: 'SysEvalDesWS_GI',
	apiUsernamePS: 'IGFPSApp',

	///
	/* CONTRASELA DE USUARIOS AUTORIZADOS */
	apiPasswordIT: '975318642',
	apiPasswordGCH: '975318642',
	apiPasswordGI: '975318642',
	apiPasswordPS: '975318642',
};
