import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

import { TrabajadorInfoDialogComponent } from '../../_shared/trabajador-info-dialog/trabajador-info-dialog.component';
import { ObservacionDialogComponent } from '../../_shared/observacion-dialog/observacion-dialog.component';
import { ShowFotoDialogComponent } from '../../_shared/show-foto-dialog/show-foto-dialog.component';
import { TablaMensajesComponent } from '../../_shared/tabla-mensajes/tabla-mensajes.component';
// import { EnableDisabledComponent } from '../../AdmisionPersonal/_shared/enable-disabled/enable-disabled.component';
// import { InsertUpdateGrupoLaborComponent } from '../../AdmisionPersonal/_shared/insert-update-grupo-labor/insert-update-grupo-labor.component';
// import { InsertUpdateLaborComponent } from '../../AdmisionPersonal/_shared/insert-update-labor/insert-update-labor.component';
// import { AddEditCargoLaboralComponent } from '../../AdmisionPersonal/_shared/add-edit-cargo-laboral/add-edit-cargo-laboral.component';
import { PostulanteModel } from '../model/postulante.model';
import { TablaCargaMasivaComponent } from '../../_shared/tabla-carga-masiva/tabla-carga-masiva.component';
import { ImportAccionMasivaComponent } from '../../_shared/import-accion-masiva/import-accion-masiva.component';
import { ResultAccionMasivaComponent } from '../../_shared/result-accion-masiva/result-accion-masiva.component';
import { SaveUpdateCampanyaComponent } from '../../procesos/campanyas/save-update-campanya/save-update-campanya.component';
import { TablaMigracionResultadosComponent } from '../../_shared/tabla-migracion-resultados/tabla-migracion-resultados.component';
import { SaveUpdateMetaCosechaComponent } from '../../_shared/save-update-meta-cosecha/save-update-meta-cosecha.component';

@Injectable({
  providedIn: 'root'
})
export class UtilsHCM_APService {

  constructor(
    private dialog: MatDialog
  ) { }

  _infoPersonal_modal(title, postulante: PostulanteModel) {
    return this.dialog.open(TrabajadorInfoDialogComponent, {
      data: { title, postulante },
      width: '900px',
      disableClose: true
    });
  }
  _infoObservacion(title, Observacion) {
    return this.dialog.open(ObservacionDialogComponent, {
      data: { title, Observacion },
      width: '500px'
    });
  }

  _showFoto(title, base_64) {
    return this.dialog.open(ShowFotoDialogComponent, {
      data: { title, base_64 },
      width: '600px'
    });
  }

  _tabla_mensajes(title, array_data) {
    return this.dialog.open(TablaMensajesComponent, {
      data: { title, array_data },
      width: '600px',
      disableClose: true
    });
  }
  _tabla_mensajes_migracion(title, array_data) {
    return this.dialog.open(TablaMigracionResultadosComponent, {
      data: { title, array_data },
      width: '1200px',
      disableClose: true
    });
  }
  _tabla_mensajes_metacosecha(title, array_data) {
    return this.dialog.open(TablaMigracionResultadosComponent, {
      data: { title, array_data },
      width: '1200px',
      disableClose: true
    });
  }
  _tabla_carga_masiva(title) {
    //console.log("asdasasd")
    return this.dialog.open(TablaCargaMasivaComponent, {
      data: { title },
      width: '900px',
      disableClose: true
    });
  }

  _import_accionMasiva(title: string = '') {
    return this.dialog.open(ImportAccionMasivaComponent, {
      data: { title },
      width: '500px',
      disableClose: true
    });
  }

  _result_accionMasiva(title: string = '', data: any) {
    return this.dialog.open(ResultAccionMasivaComponent, {
      data: { title, data },
      width: '900px',
      disableClose: true
    });
  }
  
  modal_save_update_campanya(title: string, selected_data:any,main_button){
    return this.dialog.open(SaveUpdateCampanyaComponent,{
      data: {title, selected_data,main_button},
      width: '600px',
      disableClose: true
    })
  }

  modal_save_update_metaCosecha(title: string, selected_data:any,main_button){
    return this.dialog.open(SaveUpdateMetaCosechaComponent,{
      data: {title, selected_data,main_button},
      width: '600px',
      disableClose: true
    })
  }
}
