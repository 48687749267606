import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CargaMasivaService {
  public headGCH: HttpHeaders;
  
  constructor(
    private http: HttpClient
  ) {
    this.headGCH = new HttpHeaders();
    this.headGCH = this.headGCH.append('BasicAuthorization', 'Basic ' + btoa(environment.apiUsernameGCH + ':' + environment.apiPasswordGCH));
  }

  private blobType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=UTF-8';

  listar() {
    return this.http.get(`${environment.BaseUrlWS_GCH}/CargaMasiva/Listar`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  descargar(cargaMasiva: number): any {
    return this.http.get(`${environment.BaseUrlWS_GCH}/CargaMasiva/Descargar?cargaMasiva=${cargaMasiva}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  descargarDetalle(detalleCargaMasiva: number): any {
    return this.http.get(`${environment.BaseUrlWS_GCH}/CargaMasiva/DescargarDetalle?detalleCargaMasiva=${detalleCargaMasiva}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  descargarBase64(base64, name) {
    const blob: Blob = this.base64toBlob(base64);
      const url = window.URL.createObjectURL(blob);
      const dlink = document.createElement('a');
          dlink.download = name;
          dlink.href = url;
      dlink.click();
      dlink.remove();
  }

  private base64toBlob(b64Data, sliceSize=512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: this.blobType});
    return blob;
  }
}
