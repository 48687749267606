import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CloudService {
    public headGCH: HttpHeaders;

    constructor(
        private http: HttpClient
    ) {
      this.headGCH = new HttpHeaders();
      this.headGCH = this.headGCH.append('BasicAuthorization', 'Basic ' + btoa(environment.apiUsernameGCH + ':' + environment.apiPasswordGCH));
    }
    public _getToken_byLog() {
      return this.http.get(`${environment.BaseUrlWS_GCH}/CloudService/get_LOG_CLOUD`,
        { headers: this.headGCH })
      .pipe( map( data => data ));
    }

    public get_CARTILLAS_CLOUD(model){
      return this.http.post(`${environment.BaseUrlWS_GCH}/CloudService/get_CARTILLAS_CLOUD`, model,
        { headers: this.headGCH })
      .pipe( map( data => data ));
    }

    public get_CARTILLAS_FORMA(model){
      return this.http.post(`${environment.BaseUrlWS_GCH}/CloudService/get_CARTILLAS_FORMA`, model,
        { headers: this.headGCH })
      .pipe( map( data => data ));
    }

    public get_CARTILLAS__ALL_FILLED(model){
      return this.http.post(`${environment.BaseUrlWS_GCH}/CloudService/get_CARTILLAS__ALL_FILLED`, model,
        { headers: this.headGCH })
      .pipe( map( data => data ));
    }

}
