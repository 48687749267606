import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'm-show-foto-dialog',
  templateUrl: './show-foto-dialog.component.html',
  styleUrls: ['./show-foto-dialog.component.scss']
})
export class ShowFotoDialogComponent implements OnInit {
  viewLoading: boolean = false;
  usuario: string;

  constructor(
    public dialogRef: MatDialogRef<ShowFotoDialogComponent>,
    public ref_change : ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  is_loading = true;
  imagePath;
  ngOnInit() {
    this.imagePath = this.data.base_64;
    this.ref_change.markForCheck();
  }

  onNoClick(): void {
		this.dialogRef.close();
  }


}
