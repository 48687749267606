import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';

import * as XLSX from 'xlsx';
import { TrabajadorService } from '../../_core/services/trabajador.service';

@Component({
  selector: 'm-import-accion-masiva',
  templateUrl: './import-accion-masiva.component.html',
  styleUrls: ['./import-accion-masiva.component.scss'],
  providers: [DatePipe]
})
export class ImportAccionMasivaComponent implements OnInit {

  viewLoading: boolean = false;
  formMACRO: FormGroup;
  file: File = null;
  label: string = 'Seleccione Archivo';
  fileToUpload: File = null;
  arrayBuffer: any;

  constructor(
    public dialogRef: MatDialogRef<ImportAccionMasivaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    public toastr: ToastrManager,
    private datePipe: DatePipe,
    private trabajadorService: TrabajadorService
  ) { }
  
  protected _onDestroy = new Subject<void>();
  readonly maxSize = 104857600;

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.formMACRO = this._formBuilder.group({
      Archivo: [undefined, [Validators.required]]
    });
  }

  onNoClick(): void {
    this.dialogRef.close({ data: null });
  }

  onSubmit() {
    const _controls = this.formMACRO.controls;
    if (_controls['Archivo'].value === null || _controls['Archivo'].value === '') {
      this.viewLoading = false;
      this.toastr.warningToastr('Ingrese un excel para cargar sus datos...', 'Advertencia!', {
        toastTimeout: 2000,
        showCloseButton: true,
        animate: 'fade',
        progressBar: true
      });
      return;
    } else {
      this.viewLoading = true;
      this.EXCEL_to_XML();
    }
    return;
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size <= 80000000 && event.target.files[0].size !== undefined) {
        const files: { [key: string]: File } = event.target.files;
        for (let key in files) {
          if (!isNaN(parseInt(key))) {
            this.file = files[key];
          }
        }
      } else {
        if (event.target.files[0].size > 80000000) {
          this.toastr.warningToastr('El tamaño excede a los 8Mb de capacidad. No se podra guardar', 'Advertencia!', {
            toastTimeout: 2000,
            showCloseButton: true,
            animate: 'fade',
            progressBar: true
          });
        }
        this.file = null;
      }

      if (this.file != null) {
        this.label = this.file.name;
      } else {
        this.label = 'Seleccione Archivo'
      }
    } else {
      this.label = 'Seleccione Archivo'
    }

  }

  EXCEL_to_XML() {
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheetData = workbook.Sheets[first_sheet_name];
      try{
        var XML = this.JSON_to_XML(XLSX.utils.sheet_to_json(worksheetData, { raw: true }));
        this.trabajadorService.importAccionMasivaPersonal({ XML: XML })
        .subscribe((res: any) => {
          if (res) {
            this.toastr.successToastr('Se cargo los datos correctamente', 'Éxito!', {
              toastTimeout: 3000,
              showCloseButton: true,
              animate: 'fade',
              progressBar: true
            });
            this.viewLoading = false;
            this.dialogRef.close({ data: res });
          } else {
            this.toastr.infoToastr('No se han cargado datos.', 'Información!', {
              toastTimeout: 2000,
              showCloseButton: true,
              animate: 'fade',
              progressBar: true
            });
          }
        }, (errorServicio) => {
          console.log(errorServicio);
          this.toastr.errorToastr('Se produjo un error durante la carga de datos.', 'Error!', {
            toastTimeout: 2000,
            showCloseButton: true,
            animate: 'fade',
            progressBar: true
          });
        }
        );
      }catch(error){
        this.viewLoading = false;
        this.toastr.errorToastr('Verifique el formato de fecha sea YYY-MM-DD.', 'Error!', {
          toastTimeout: 2000,
          showCloseButton: true,
          animate: 'fade',
          progressBar: true
        });
      }
    }

  }

  JSON_to_XML(ExcelJson: any) {
    let XML = '<?xml version="1.0" encoding="ISO-8859-1"?><root>';
    ExcelJson.forEach((Personal:any) => {
      let FechaInicio = Personal['Fecha Inicio Contrato']? this.datePipe.transform(Personal['Fecha Inicio Contrato'], 'yyyy-MM-dd'):'';
      let FechaFin = Personal['Fecha Fin Contrato']? this.datePipe.transform(Personal['Fecha Fin Contrato'], 'yyyy-MM-dd'):'';
      let FechaCese = Personal['Fecha Cese']? this.datePipe.transform(Personal['Fecha Cese'], 'yyyy-MM-dd'):'';
      XML += '<Personal DNI="' + Personal['DNI'] + '" ';
      XML += 'Sociedad="' + Personal['Sociedad'] + '" ';
      XML += 'FechaInicio="' + FechaInicio + '" ';
      XML += 'FechaFin="' + FechaFin + '" ';
      XML += 'FechaCese="' + FechaCese + '" ';
      XML += 'Accion="' + Personal['Accion'] + '" ';
      XML += 'Mensaje="" />';
    });
    XML += '</root>';
    return XML;
  }

}

