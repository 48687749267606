import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CampanyaService {

    public headGCH: HttpHeaders;
    constructor(
      private http: HttpClient,
    ) {
      this.headGCH = new HttpHeaders();
      this.headGCH = this.headGCH.append('BasicAuthorization', 'Basic ' + btoa(environment.apiUsernameGCH + ':' + environment.apiPasswordGCH));
    }


  public getFundoLst(Empresa, status) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Campanya/PMMM_getFundoLst?prmEmpresa=${Empresa}&prmStatus=${status}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }
  public getModuloByFundo(Fundo, status) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Campanya/PMMM_getModuloByFundo?prmFundo=${Fundo}&prmStatus=${status}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }
  public getTurnoByModulo(Modulo, status) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Campanya/PMMM_getTurnoByModulo?prmModulo=${Modulo}&prmStatus=${status}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }
  public getLotesByTurno(Turno, status) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Campanya/PMMM_getLotesByTurno?prmTurno=${Turno}&prmStatus=${status}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public getCampanya_list(status, fundo,modulo,turno) {
    return this.http.get(`${environment.BaseUrlWS_GCH}/Campanya/PMMM_getCampanya_list?`+
                    `prmStatus=${status}`+
                    `&prmFundo=${fundo}`+
                    `&prmModulo=${modulo}`+
                    `&prmTurno=${turno}`,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public saveUpdateCampanya(model) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Campanya/PMMM_saveUpdateCampanya`,model,
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }

  public enableDisableCampanya(id, future_status) {
    return this.http.post(`${environment.BaseUrlWS_GCH}/Campanya/PMMM_enableDisableCampanya`,{ID: id,Future_Status: future_status},
      { headers: this.headGCH })
    .pipe( map( data => data ));
  }


  

}
