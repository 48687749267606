import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export enum SessionKeys {
	TOKEN = 'Authorization',
	PERMISSIONS = 'permissions',
	USUARIO = 'usuario',
	USUARIOID = 'usuarioid',
	NOMBRE = 'nombre',
	SOCIEDAD = 'sociedad',
	EMPRESA = 'empresa',
	AREA = 'area',
	TRABAJADOR = 'trabajador',
	EMAIL = 'email',
	POSICION = 'posicion',
	TIPOUSUARIO = 'tipousuario',
	CECO= 'ceco',
}

@Injectable()
export class TokenStorage {
	/**
	 * Get access token
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		const token: string = <string>localStorage.getItem(SessionKeys.TOKEN);
		return of(token);
	}

	/**
	 * Get refresh token
	 * @returns {Observable<string>}
	 */
	public getRefreshToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('refreshToken');
		return of(token);
	}

	/**
	 * Get user roles in JSON string
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		const roles: any = localStorage.getItem('userRoles');
		try {
			return of(JSON.parse(roles));
		} catch (e) {}
	}
	
	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessRol(rol: string): TokenStorage {
		localStorage.setItem('rol', rol);

		return this;
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessEmpresa(cliente: string): TokenStorage {
		localStorage.setItem('cliente', cliente);

		return this;
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessNombres(nombres: string): TokenStorage {
		localStorage.setItem('nombres', nombres);

		return this;
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessEmail(correo: string): TokenStorage {
		localStorage.setItem('correo', correo);

		return this;
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessUsuario(cuentaUsuario: string): TokenStorage {
		localStorage.setItem('cuentaUsuario', cuentaUsuario);

		return this;
	}

	/**
	 * Set access token
	 * @returns {TokenStorage}
	 */
	public setAccessToken(token: string): TokenStorage {
		localStorage.setItem(SessionKeys.TOKEN, token);

		return this;
	}

	/**
	 * Set refresh token
	 * @returns {TokenStorage}
	 */
	public setRefreshToken(token: string): TokenStorage {
		localStorage.setItem('refreshToken', token);

		return this;
	}

	/**
	 * Set user roles
	 * @param roles
	 * @returns {TokenStorage}
	 */
	public setUserRoles(roles: any): any {
		console.log(JSON.parse(roles));
		console.log(JSON.stringify(roles));
		if (roles != null) {
			localStorage.setItem('userRoles', JSON.stringify(JSON.parse(roles)));
		}

		return this;
	}

	public set(key: string, value: any): void{
		//console.log("seteo");
		localStorage.setItem(key, JSON.stringify(value));
	}

	public get(key: string): any{
		return JSON.parse(localStorage.getItem(key));
	}

	/**
	 * Remove tokens
	 */
	public clear() {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('userRoles');
		localStorage.removeItem(SessionKeys.TOKEN);
	}
}
