import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { forEach } from 'lodash';

@Component({
  selector: 'm-tabla-migracion-resultados',
  templateUrl: './tabla-migracion-resultados.component.html',
  styleUrls: ['./tabla-migracion-resultados.component.scss'],
  providers: [DatePipe]
})
export class TablaMigracionResultadosComponent implements OnInit {

  viewLoading: boolean = false;
  usuario: string;

  constructor(
    public dialogRef: MatDialogRef<TablaMigracionResultadosComponent>,
    public ref_change : ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe
  ) { }
  is_loading = true;
  listData: MatTableDataSource<any>= new MatTableDataSource([]);
  @ViewChild(MatSort) MatSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['Nro','CodigoCultivo','Cultivo','CodigoVariedad','Variedad',
  'NombreProyeccion','FechaProyeccion','Anio','NroSemana','Inicio','Fin','Kilos','MSG'];



  ngOnInit() {
    this.listData = new MatTableDataSource(this.data.array_data);
    this.listData.sort = this.MatSort;
    this.listData.paginator = this.paginator;
  }
  display_data(val,type){
    return type=='text'?val:(type=='date'?this.datePipe.transform(val,'dd/MM/yyyy'):this.datePipe.transform(val,'dd/MM/yyyy hh:mm:ss'));
  }
  searchKey: string;
  searchInside_Table() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  onNoClick(): void {
		this.dialogRef.close();
  }


}
