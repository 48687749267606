import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';

@Component({
  selector: 'm-tabla-mensajes',
  templateUrl: './tabla-mensajes.component.html',
  styleUrls: ['./tabla-mensajes.component.scss']
})
export class TablaMensajesComponent implements OnInit {

  viewLoading: boolean = false;
  usuario: string;

  constructor(
    public dialogRef: MatDialogRef<TablaMensajesComponent>,
    public ref_change : ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  is_loading = true;
  listData: MatTableDataSource<any>= new MatTableDataSource([]);
  @ViewChild(MatSort) MatSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['DNI','FullName','MSJ'];

  ngOnInit() {
    this.listData = new MatTableDataSource(this.data.array_data);
    this.listData.sort = this.MatSort;
    this.listData.paginator = this.paginator;
  }
  searchKey: string;
  searchInside_Table() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  onNoClick(): void {
		this.dialogRef.close();
  }


}
